<template>
  <!-- RightWingComponent -->
  <div
    class="shrink-0 mr-12"
    :class="[
      storage.isOpenRightWing ? 'ml-12' : 'w-auto',
      {
        'w-[52rem]': storage.isOpenRightWing && isWideRightWing,
        'w-[28rem]': storage.isOpenRightWing && !isWideRightWing
      }
    ]"
  >
    <div class="flex justify-end mb-24">
      <button
        type="button"
        class="rounded-full bg-blue50 flex items-center justify-center w-32 h-32"
        @click="setLocalStorage('isOpenRightWing', !storage.isOpenRightWing)"
      >
        <s-icon
          icon="ic-v2-control-top-line"
          :class="storage.isOpenRightWing ? 'rotate-90' : '-rotate-90'"
          size="xl"
          class="text-on-surface-elevation-2"
        />
      </button>
    </div>
    <div v-if="storage.isOpenRightWing">
      <slot></slot>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useAppStore } from '@/stores/app.store';

const props = withDefaults(
  defineProps<{
    isOpenRightWing?: boolean;
    isWideRightWing?: boolean;
  }>(),
  {
    isOpenRightWing: true
  }
);

const { storage, setLocalStorage } = useAppStore();

const init = () => {
  const isOpenRightWing = localStorage.getItem('isOpenRightWing');

  if (isOpenRightWing === null) {
    setLocalStorage('isOpenRightWing', props.isOpenRightWing);

    return;
  }

  setLocalStorage('isOpenRightWing', JSON.parse(isOpenRightWing));
};

init();
</script>
